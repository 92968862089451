import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {faGitlab} from "@fortawesome/free-brands-svg-icons";
import React from "react";
import {DropdownButton} from "./DropdownButton";
import {setCookie} from "../utils/cookie";
import {IProvider, IProviderConfig} from "../types/provider";
import {AppConfig} from "../AppConfig";
import {useAuth} from "@clerk/clerk-react";

export interface ProviderButtonProps {
    name: string
    config: IProviderConfig
    connected?: IProvider
    update?: () => void
    onClick?: (config: IProviderConfig) => void
    disabled?: boolean
    autoDisconnect?: boolean
}

function iconFromType(type: string) {
    switch (type) {
        case "gitlab":
            return <FontAwesomeIcon icon={faGitlab}/>
    }
}

function providerUrl(redirect_uri: string, state: string, provider: IProviderConfig) {
    if (provider.type === 'gitlab') {
        return `${provider.url}/oauth/authorize?client_id=${provider.client_id}&redirect_uri=${redirect_uri}&response_type=code&state=${state}&scopes=${encodeURIComponent((provider.scopes ?? []).join(","))}`
    }
    if (provider.type === 'slack') {
        return `${provider.url}/oauth/v2/authorize?client_id=${provider.client_id}&redirect_uri=${redirect_uri}&state=${state}&user_scope=${encodeURIComponent((provider.user_scopes ?? []).join(","))}&scope=${encodeURIComponent((provider.scopes ?? []).join(","))}`
    }
    throw new Error(`Unsupported provider type: ${provider.type}`)
}

export function ProviderButton({
                                   name,
                                   config,
                                   connected,
                                   update,
                                   onClick,
                                   disabled,
                                   autoDisconnect
                               }: ProviderButtonProps) {
    const {getToken} = useAuth()
    const connectProvider = () => {
        onClick?.(config)
        if (!connected) {
            const state = window.crypto.randomUUID();
            setCookie(AppConfig.provider.cookieName, JSON.stringify({state}), 1)
            window.open(providerUrl(`${window.location.origin}/provider/connect/${config.id}`, state, config));
            window.addEventListener("message", ev => {
                if (ev.data === "done") {
                    update?.()
                }
            }, false)
        }
    }

    const icon = iconFromType(config.type)
    const options = [
        {
            name: 'Disconnect',
            icon: <FontAwesomeIcon icon={faTrash} style={{marginRight: '5px'}}/>,
            onClick: () => {
                getToken().then(token => {
                    fetch(`${AppConfig.apiHost}/api/v1/provider/${config?.id}/connect`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'accept': 'application/json',
                            'content-type': 'application/json',
                        },
                        method: 'DELETE',
                    }).then(() => {
                        update?.()
                    }).catch(console.log)
                })
            },
            enabled: !!connected
        }
    ];
    return <>
        <DropdownButton name={name} options={options} onClick={() => {
            if (autoDisconnect) {
                connected ? options[0].onClick() : connectProvider()
            } else {
                connectProvider()
            }
        }} icon={icon} disabled={disabled}/>
    </>
}
