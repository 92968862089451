import {useAuth} from "@clerk/clerk-react";

import useSWR from 'swr'
import {useFirebase} from "../firebase";
import {collection, doc, onSnapshot, DocumentReference, setDoc} from "firebase/firestore";
import {useEffect, useState} from "react";
import {AppConfig} from "../AppConfig";

export const useClerkSWR = <T>(url: string) => {
    const {getToken} = useAuth()

    const fetcher = async (input: RequestInfo | URL, init?: RequestInit) => {
        return fetch(input, {
            ...init,
            headers: {
                Authorization: `Bearer ${await getToken()}`,
                ...init?.headers,
            }
        }).then((res) => res.json())
    }

    return useSWR<T>(`${AppConfig.apiHost}${url}`, fetcher)
}

export const useDoc = <T>(path: string) => {
    const {db, userCredentials} = useFirebase()
    const [snapshot, setSnapshot] = useState<T>()
    const [isLoading, setIsLoading] = useState(true)
    const [ref, setRef] = useState<DocumentReference>()

    useEffect(() => {
        if (!userCredentials){
            // if the userCredentials are not set then our firebase connection is refreshing
            return
        }
        const reference = doc(db, path);
        setRef(reference)
        return onSnapshot(reference, snap => {
            const data = snap.data();
            setSnapshot(data as T)
            setIsLoading(false)
        })
    }, [path, userCredentials, db])

    return {snapshot, isLoading, ref}
}

export const setData = <T>(ref: DocumentReference, data: Partial<T>, merge: boolean): Promise<void> => {
    return setDoc(ref, data, {merge})
}

export const useCollection = <T>(path: string) => {
    const {db, userCredentials} = useFirebase()
    const [snapshot, setSnapshot] = useState<T[]>()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (!userCredentials){
            // if the userCredentials are not set then our firebase connection is refreshing
            return
        }
        const reference = collection(db, path);
        return onSnapshot(reference, snap => {
            setSnapshot(snap.docs.map(d => d.data()) as T[])
            setIsLoading(false)
        })
    }, [path, userCredentials, db])

    return {snapshot, isLoading}
}
