// toast-utils.tsx
import { createContext, useContext, useState, ReactNode } from 'react';
import { Alert, Snackbar } from '@mui/material';

type Severity = 'error' | 'warning' | 'info' | 'success';

interface ToastContextType {
    showToast: (message: string, severity?: Severity) => void;
}

interface ToastProviderProps {
    children: ReactNode;
}

// Create context with a default value
export const ToastContext = createContext<ToastContextType>({
    showToast: () => null, // default no-op function
});

export const ToastProvider = ({ children }: ToastProviderProps) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState<Severity>('success');

    const showToast = (message: string, severity: Severity = 'success') => {
        setMessage(message);
        setSeverity(severity);
        setOpen(true);
    };

    const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setOpen(false);
    };

    return (
        <ToastContext.Provider value={{ showToast }}>
            {children}
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={severity}
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </ToastContext.Provider>
    );
};

// Custom hook with type safety
export const useToast = (): ToastContextType => {
    const context = useContext(ToastContext);

    if (context === undefined) {
        throw new Error('useToast must be used within a ToastProvider');
    }

    return context;
};