import React from 'react';
import {Box} from '@mui/material';

export interface CenteredElementProps  {
    children: any;
    height?: string;
    className?: string;
    style?: React.CSSProperties;

}

export const CenteredElement = ({children, height = '100vh', style, className}: CenteredElementProps) => {
    return (
        <Box
            style={style}
            className={className}
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight={height}
        >
            {children}
        </Box>
    );
};
