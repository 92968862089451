import * as React from 'react'
import {useEffect} from 'react'
import {OrganizationSwitcher, useAuth, UserButton} from '@clerk/clerk-react'
import {Outlet, useNavigate} from 'react-router-dom'
import {
    AppBar,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    FormControlLabel,
    FormGroup, FormHelperText,
    Switch,
    Toolbar,
    Typography
} from '@mui/material';
import AddIcon from "@mui/icons-material/Add";
import HubIcon from '@mui/icons-material/Hub';
import SettingsIcon from '@mui/icons-material/Settings';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook} from '@fortawesome/free-solid-svg-icons';
import {ProviderButton} from "../components/ProviderButton";
import {gitlab} from "../utils/config";
import {setData, useDoc} from "../utils/api";
import {IProvider} from "../types/provider";
import {CenteredElement} from "../components/CenteredElement";

interface orgSettings {
    gitlab_settings: {
        dont_await_mr_assign: boolean
        enabled_hooks: string[]
    }
}

const OrgSettingsPage = () => {
    const {orgId} = useAuth()
    const {snapshot, isLoading, ref} = useDoc<orgSettings>(`orgs/${orgId}`)

    return (
        <>
            <div>
                <h1>Settings</h1>
                {
                    isLoading ?
                        <CenteredElement height={"100%"}><CircularProgress/></CenteredElement> :
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={snapshot?.gitlab_settings.dont_await_mr_assign} onChange={event => {
                                    if (ref) {
                                        setData(ref, {
                                            ...snapshot,
                                            gitlab_settings: {
                                                ...(snapshot?.gitlab_settings ?? {}),
                                                dont_await_mr_assign: event.target.checked
                                            }
                                        }, true).catch(console.error)
                                    }
                                }}/>}
                                label="Process all Pull/Merge Requests"
                            />
                            <FormHelperText>Process all merge requests, not just those that are created or assigned to us.</FormHelperText>
                        </FormGroup>
                }
            </div>
        </>
    )
}

const ConnectionPage = () => {
    const {userId} = useAuth()
    const {snapshot, isLoading} = useDoc<IProvider>(`users/${userId}/providers/gitlab`);

    return (
        <div>
            <h1>Connections</h1>
            <p>Here you can manage your user connections. Disconnecting a provider will not stop Aether from processing
                data.</p>
            <div style={{borderBottom: "1px solid #eee", marginBottom: "5px"}}/>
            <h1>Repository Connections</h1>
            {
                isLoading ?
                    <CenteredElement height={"100%"}><CircularProgress/></CenteredElement> :
                    <ProviderButton name={snapshot ? "Disconnect GitLab" : "Connect GitLab"} config={gitlab}
                                    connected={snapshot}/>
            }

        </div>
    )
}

export default function HomeLayout() {
    const {userId, isLoaded} = useAuth()
    const navigate = useNavigate()

    const handleNavigation = (event: React.MouseEvent<HTMLAnchorElement>, path: string) => {
        event.preventDefault();
        navigate(path);
    };

    useEffect(() => {
        if (isLoaded && !userId) {
            navigate('/sign-in')
        }
    }, [isLoaded, userId, navigate])

    if (!isLoaded) return (<div>'Loading...'</div>)


    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <Box display="flex" alignItems="center" sx={{flexGrow: 1}}>
                        <Button
                            color="inherit"
                            href="/h/-/dashboard"
                            onClick={(e) => handleNavigation(e as React.MouseEvent<HTMLAnchorElement>, '/h/-/dashboard')}
                            startIcon={
                                <Avatar
                                    src={`${process.env.PUBLIC_URL}/images/icon_200_200.png`}
                                    alt="AetherCodeAI"
                                />
                            }
                            sx={{textTransform: 'none'}}
                        >
                            <Typography variant="h6" component="div">
                                AetherCodeAI
                            </Typography>
                        </Button>

                        <OrganizationSwitcher
                            afterSelectOrganizationUrl={(org) => {
                                return `/h/${org.slug}/dashboard`
                            }}
                            afterSelectPersonalUrl={"/h/user/dashboard"}
                            hidePersonal={true}
                        >

                            <OrganizationSwitcher.OrganizationProfilePage
                                label="Settings"
                                labelIcon={<SettingsIcon/>}
                                url="terms"
                            >
                                <OrgSettingsPage/>
                            </OrganizationSwitcher.OrganizationProfilePage>
                        </OrganizationSwitcher>
                        <Button
                            href="/h/-/dashboard"
                            onClick={(e) => handleNavigation(e as React.MouseEvent<HTMLAnchorElement>, '/h/-/dashboard')}
                            sx={{my: 2, color: 'white', display: 'block'}}
                        >
                            {"Dashboard"}
                        </Button>
                        <Button
                            href="/h/-/projects"
                            onClick={(e) => handleNavigation(e as React.MouseEvent<HTMLAnchorElement>, '/h/-/projects')}
                            sx={{my: 2, color: 'white', display: 'block'}}
                        >
                            {"Projects"}
                        </Button>
                    </Box>

                    <>
                        <UserButton showName={true}>

                            <UserButton.UserProfilePage label="Connections" url="custom" labelIcon={<HubIcon/>}>
                                <ConnectionPage/>
                            </UserButton.UserProfilePage>
                            <UserButton.MenuItems>
                                <UserButton.Action
                                    label="Open chat"
                                    labelIcon={<AddIcon/>}
                                    onClick={() => alert('init chat')}
                                />
                                <UserButton.Link href={"https://docs.aethercoding.ai"} label={"Docs"}
                                                 labelIcon={<FontAwesomeIcon icon={faBook}/>}/>
                            </UserButton.MenuItems>
                        </UserButton>
                    </>
                </Toolbar>
            </AppBar>
            <Container component="main" sx={{mt: 4}}>
                <Outlet/>
            </Container>`
        </>
    );
}