import {ProviderButton} from "../../components/ProviderButton";
import {useClerkSWR, useDoc} from "../../utils/api";
import {useAuth} from "@clerk/clerk-react";
import {IProvider, IProviderConfig, IRepository} from "../../types/provider";
import {CenteredElement} from "../../components/CenteredElement";
import {
    Avatar,
    CircularProgress,
    Container,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    TextField
} from "@mui/material";
import React, {useState} from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import {AppConfig} from "../../AppConfig";
import {gitlab} from "../../utils/config";
import { useDebounce } from "../../utils/funcs";
import {useToast} from "../../components/alert/toast";

function RepoList({repos, onClick, type}: { repos?: IRepository[], onClick?: (repo: IRepository, type: string) => void, type: string }) {
    return (
        <>
            <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                {
                    (repos ?? []).map((repo) => {
                        return <ListItemButton onClick={() => {
                            onClick?.(repo, type)
                        }} key={repo.id}>
                            <ListItemAvatar>
                                <Avatar src={repo.avatar}/>
                            </ListItemAvatar>
                            <ListItemText primary={repo.name} secondary={`${repo.type} ${type}`}/>
                        </ListItemButton>
                    })
                }
            </List>
        </>
    );
}


function SelectProject({provider, onClick}: {
    provider: IProviderConfig | undefined,
    onClick?: (repo: IRepository, type: string) => void
}) {
    const [search, setSearch] = useState("")
    const debouncedSearch = useDebounce(search, 500); // 500ms delay

    const {data, isLoading} = useClerkSWR<{repos:IRepository[], groups: IRepository[]}>(
        // Only make the API call when debouncedSearch changes
        `/api/v1/provider/${gitlab.id}/search?q=${debouncedSearch}`
    );

    return (
        <Container>
            <Container>
                <Box>
                    <p>Select the {provider?.name} project to connect</p>
                </Box>
            </Container>

            <Container>
                <Box component="form" noValidate sx={{p: '2px 4px', display: 'flex', alignItems: 'center'}}>
                    <TextField
                        fullWidth
                        label="Search"
                        id="search"
                        value={search}
                        onChange={(e) => {
                            setSearch(e.currentTarget.value)
                        }}
                    />
                </Box>
            </Container>
            {
                isLoading &&
                <Container style={{marginTop: "20px"}}><CenteredElement height={"20%"}><CircularProgress/></CenteredElement></Container>
            }
            {
                !isLoading && <RepoList repos={data?.groups ?? []} onClick={onClick} type={'group'}/>
            }
            {
                !isLoading && <RepoList repos={data?.repos ?? []} onClick={onClick} type={'project'}/>
            }
        </Container>
    );
}
function ConfirmAndValidate({provider, repo, onCreate, orgId}: {
    provider: IProviderConfig | undefined,
    repo: IRepository | undefined,
    onCreate: () => void,
    orgId?: string | null
}) {
    const {snapshot, isLoading} = useDoc<IProvider>(`orgs/${orgId}/projects/${provider?.id}-${repo?.id}`);
    const [creating, setCreating] = useState(false)

    return (
        <>
            <Container>
                <Container>
                    <Box>
                        <p>Confirm you want to connect the project from {provider?.name}</p>
                        <p>{repo?.name}</p>
                    </Box>
                </Container>
                {
                    isLoading &&
                    <Container><CenteredElement height={"20%"}><CircularProgress/></CenteredElement></Container>
                }
                {
                    !isLoading && snapshot && <p>Repo already connected.</p>
                }
                <Container>
                    {creating && <CenteredElement height={"100%"} style={{marginBottom: '10px'}}><CircularProgress/></CenteredElement>}
                    <CenteredElement height={"100%"}>
                        <Button variant="contained" color={"primary"} onClick={() => {
                            setCreating(true)
                            onCreate()
                        }} disabled={isLoading || !!snapshot || creating}>
                            Connect
                        </Button>
                    </CenteredElement>
                </Container>
            </Container>
        </>
    );
}

export const NewProjectForm = ({onComplete}: { onComplete: () => void }) => {
    const {userId, getToken, orgId} = useAuth()
    const {snapshot, isLoading} = useDoc<IProvider>(`users/${userId}/providers/gitlab`);
    const [provider, setProvider] = useState<IProviderConfig>()
    const [type, setType] = useState<string | null>(null)
    const [repo, setRepo] = useState<IRepository>()
    const [showBack, setShowBack] = useState(false)
    const {showToast} = useToast();

    const steps = [
        {
            name: 'Select Provider',
            children:  <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 3,
                    minHeight: 200,
                    width: "100%"
                }}
            >
                <Typography>
                    Select the provider to connect a repository
                </Typography>

                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <ProviderButton
                        name={snapshot ? "Use GitLab" : "Connect GitLab"}
                        config={gitlab}
                        connected={snapshot}
                        onClick={(provider) => {
                            setProvider(provider);
                            setShowBack(true);
                            handleNext();
                        }}
                    />
                )}
            </Box>
        },
        {
            name: 'Select Repository',
            children: <SelectProject provider={provider} onClick={(repo, type) => {
                setRepo(repo)
                setType(type)
                handleNext()
            }}/>,
        },
        {
            name: 'Create Project',
            children: <ConfirmAndValidate orgId={orgId} provider={provider} repo={repo} onCreate={() => {
                getToken().then((token) => {
                    fetch(`${AppConfig.apiHost}/api/v1/provider/${provider?.id}/connect/${repo?.id}?type=${type}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'accept': 'application/json',
                            'content-type': 'application/json',
                        },
                        method: 'POST',
                    }).then((resp) => {
                        if (resp.status !== 200) {
                            showToast("Cannot add project/group.", "error")
                        }
                        onComplete()
                    }).catch(console.log)
                })
            }}/>
        }
    ];

    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Box sx={{width: '100%'}}>
            <Stepper activeStep={activeStep}>
                {steps.map((step, index) => {
                    return (
                        <Step key={index}>
                            <StepLabel>{step.name}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <React.Fragment>
                <Typography sx={{mt: 2, mb: 1}}>{steps[activeStep].name}</Typography>
                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                    {steps[activeStep].children}
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                    {
                        showBack &&
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{mr: 1}}
                        >
                            Back
                        </Button>
                    }
                </Box>
            </React.Fragment>
        </Box>
    );
}
