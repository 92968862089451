import {Box, CircularProgress, Container, Typography} from "@mui/material";
import SectionHeaderComponent from "../components/HeaderComponent";
import React, {useState} from "react";
import {Outlet} from "react-router-dom";
import CustomDrawer from "../components/CustomDrawer";
import {NewProjectForm} from "../app/projects/NewProjectForm";
import {useAuth} from "@clerk/clerk-react";
import {useCollection} from "../utils/api";
import {CenteredElement} from "../components/CenteredElement";
import {ProjectList} from "../components/project/ProjectList";
import {FSProject} from "../types/provider";
import Button from "@mui/material/Button";

export const ProjectLayout = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const {orgId} = useAuth()
    const {snapshot: projects, isLoading} = useCollection<FSProject>(`orgs/${orgId}/projects`)

    const handleDrawerOpen = (): void => setIsDrawerOpen(true);
    const handleDrawerClose = (): void => setIsDrawerOpen(false);
    return (
        <Container maxWidth="lg">
            <Box display="flex" flexDirection="column" alignItems="center" my={4}>
                <SectionHeaderComponent
                    title="Projects"
                    onAddClick={handleDrawerOpen}
                />
            </Box>
            <CustomDrawer
                isOpen={isDrawerOpen}
                onClose={handleDrawerClose}
            >
                <Typography variant="h6" component="h3" gutterBottom>
                    Create New Project
                </Typography>
                <NewProjectForm onComplete={() => {
                    handleDrawerClose()
                }}/>
            </CustomDrawer>
            {
                isLoading ?
                    <CenteredElement height={"100%"}><CircularProgress/></CenteredElement> :
                    (projects?.length ?? 0) > 0 ? <ProjectList projects={projects}/> :
                        <>
                            <CenteredElement height={"100%"}>
                                <div style={{marginBottom: "10px"}}>
                                    You currently have no projects configured.
                                </div>
                            </CenteredElement>
                            <CenteredElement height={"100%"}>
                                <Button variant="contained" color={"primary"} onClick={handleDrawerOpen}>Connect Project</Button>
                            </CenteredElement>
                        </>
            }
            <Outlet/>
        </Container>
    )
}