import {Avatar, List, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material";
import {FSProject} from "../../types/provider";
import React from "react";
import {ButtonMenu} from "../button/ButtonMenu";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {useAuth} from "@clerk/clerk-react";
import {AppConfig} from "../../AppConfig";

export interface ProjectListProps {
    projects?: FSProject[]
}

export const ProjectList = ({projects}: ProjectListProps) => {
    const {getToken} = useAuth()
    console.log(projects)
    return (
        <List sx={{width: '100%', bgcolor: 'background.paper'}}>
            {
                (projects ?? []).map((project: FSProject) => {
                    return (
                        <ListItemButton key={project.ID}>
                            <ListItemAvatar>
                                <Avatar src={project.Avatar}/>
                            </ListItemAvatar>
                            <ListItemText primary={project.Name} secondary={`${project.Type}${project.HttpGitURL === '' ? ' group' : ''}`}/>
                            <ButtonMenu options={[
                                {label: "Open", onClick: ()=> { window.open(project.URL, "_blank")?.focus() }, href: project.URL},
                                {label: "Disconnect", onClick: ()=> {
                                        getToken().then(()=>{
                                            getToken().then((token) => {
                                                fetch(`${AppConfig.apiHost}/api/v1/provider/${project?.Type}/connect/${project?.ID}`, {
                                                    headers: {
                                                        Authorization: `Bearer ${token}`,
                                                    },
                                                    method: 'DELETE',
                                                }).catch(console.log)
                                            })

                                        })
                                    }},
                            ]} icon={faBars} />
                        </ListItemButton>
                    )
                })
            }
        </List>
    )
}