import {useParams, useSearchParams} from "react-router-dom";
import {Alert, Card, CardContent, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink} from "@fortawesome/free-solid-svg-icons/faLink";
import {eraseCookie, getCookie} from "../utils/cookie";
import {AppConfig} from "../AppConfig";
import {useAuth} from "@clerk/clerk-react";
import {CenteredElement} from "../components/CenteredElement";

let hasRun = false
let err: string | null = null

export function ProviderConnect() {
    const {getToken} = useAuth()
    const [searchParams,] = useSearchParams();
    const {provider} = useParams()
    const [error, setError] = useState<string | null>(err)
    const [complete, setComplete] = useState(hasRun)
    const [cookie, setCookie] = useState(getCookie(AppConfig.provider.cookieName))
    const [token, setToken] = useState<string | null>(null)

    const setErr = (e: string | null) => {
        err = e
        setError(e)
    }
    getToken().then((tkn) => {
        if (!tkn) {
            return
        }
        setToken(tkn)
    })

    useEffect(() => {
        if (!cookie || !token) {
            return
        }
        const state = searchParams.get("state");
        if (!state) {
            return
        }

        const stateObj = JSON.parse(cookie);
        if (stateObj.state !== state) {
            setErr("malformed state object: please try again.")
            eraseCookie(AppConfig.provider.cookieName)
            setCookie(null)
            return
        }

        if (hasRun) {
            return
        }
        hasRun = true
        let timeout: string | number | NodeJS.Timeout | undefined;
        fetch(`${AppConfig.apiHost}/api/v1/provider/${provider}/connect`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'accept': 'application/json',
                'content-type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                    state: `${state}`,
                    code: searchParams.get("code"),
                    id: provider,
                    redirect_uri: window.location.origin + window.location.pathname,
                }
            )
        })
            .then((val) => {
                setErr(null)
                if (val.status === 201) {
                    setComplete(true)
                    window.opener.postMessage("done", window.location.origin)
                    timeout = setTimeout(window.close, 2000)
                } else {
                    val.text().then(setErr).catch(setErr)
                }

            }).catch(reason => {
            setErr(`${reason}`)
        }).finally(() => {
            eraseCookie(AppConfig.provider.cookieName)
        })

        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
            setErr(null)
        };
    }, [setError, provider, searchParams, getToken, cookie, token]);

    return (
        <CenteredElement>
            <Card>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        <FontAwesomeIcon icon={faLink}/>
                        {complete ? `Connected to ${provider}; tab will close in 2 seconds.` : `Connecting provider ${provider}...`}
                        {error ?
                            <div className={"padding-top-25"}>
                                <Alert severity="error">{JSON.stringify(error)}</Alert>
                            </div>
                            : " "}
                    </Typography>
                </CardContent>
            </Card>
        </CenteredElement>
    )
}